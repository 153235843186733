.article-executive-summary {
    margin-bottom: 2rem;
    
    border-bottom: solid #888888;
	padding-bottom: 30px;

    p {
        @extend .article-paragraph;
    }
    h4 {
        color: $color-zeus-black;
        font-size: 1.2rem;
        margin-bottom: 0.75rem;
        & + span, & + p {
            font-size: 18px;
            line-height: 27px;
            color: $color-ship-gray;
            margin-bottom: 0;
        }
    }
	ul{
	   list-style: disc;
	   margin-left: 12px;
	   @media screen and (max-width: 1024px)  {
	     margin-left: 18px;
	   }
	   li{
	    list-style: disc;
	   }
	}
	
	ol{
		margin-left: 12px;
		list-style: decimal;
		@media screen and (max-width: 1024px)  {
	     margin-left: 18px;
	    }
		li{
			list-style: decimal;
	    } 
	}
}
.article-collections-link {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 2rem;
    border-bottom: solid #888888;
	padding-bottom: 30px;
    @include textMix('Roboto', 15px, 400);
}
.root.paywall-protected{
	ul{
	   list-style: disc;
	   margin-left: 12px;
	   @media screen and (max-width: 1024px)  {
	     margin-left: 18px;
	   }
	   li{
	    list-style: disc;
	   }
	}
	ol{
		margin-left: 12px;
		list-style: decimal;
		@media screen and (max-width: 1024px)  {
	     margin-left: 18px;
	    }
		li{
			list-style: decimal;
	    } 
	}
}